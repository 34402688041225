<template>
  <v-container>
    <v-card elevation="2">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Bonus locations</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-switch
          v-model="isGroup"
          class="caption font-weight-thin"
          dense
          label="Group by page"
          color="blue"
          @change="groupByPage()"
        ></v-switch>
        <v-data-table
          dense
          :group-by="tableGroupBy"
          :sort-by="tableSortBy"
          :headers="tableHeaders"
          :items="BonusLocationsRidingOnlyGetter"
          item-key="name"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:group.header="{ group, headers }">
            <th :colspan="headers.length" class="subtitle-2">
              Page {{ group }}
            </th>
          </template>

          <template v-slot:item.category="{ item }">
            <v-chip
              v-if="item.category !== null"
              :color="item.getSymbolcolor() + ' lighten-3'"
              dark
              pill
              small
              >{{ item.category }}</v-chip
            >
          </template>

          <template v-slot:item.symbol="{ item }">
            <v-icon small :color="item.getSymbolcolor()">{{
              item.getMaterialicon()
            }}</v-icon>
          </template>

          <template v-slot:item.value="{ item }">
            {{ item.getTshirtsize() }}
          </template>

          <template v-slot:item.timezoneid="{ item }">
            {{ item.timezoneShort }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small @click="showBonusDetailDialog(item)"
              >mdi-text-box-search-outline</v-icon
            >
          </template>
        </v-data-table></v-card-text
      ></v-card
    >
    <dialogBonusDetail
      v-model="showLocationDetailsDialog"
      :selectedBonus="selectedBonus"
      :mapMarkers="mapMarkers"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import dialogBonusDetail from '@/components/dialogs/dialogBonusDetail'
import { BonusLocationsPrototype } from '@/components/prototypes/bonuslocations.js'
import * as Luxon from 'luxon'
export default {
  name: 'Bonuses',
  components: { dialogBonusDetail },
  data() {
    const data = {
      mapMarkers: [
        {
          position: {
            lat: 44.902517,
            lng: -92.783133
          },
          name: 'placeholder',
          icon: null
        }
      ],
      isGroup: true,
      tableGroupBy: 'page',
      tableSortBy: 'entryorder',
      showLocationDetailsDialog: false,
      selectedBonus: new BonusLocationsPrototype(),
      tableHeaders: [
        { text: 'Location', align: 'start', sortable: true, value: 'name' },
        { text: 'Longname', sortable: true, value: 'longname' },
        { text: 'Points', sortable: true, value: 'points' },
        { text: 'Page', sortable: true, value: 'page' },
        { text: 'Category', sortable: true, value: 'category' },
        { text: 'Timezone', sortable: false, value: 'timezoneid' },
        { text: 'Value', sortable: true, value: 'value' },
        { text: 'Symbol', align: 'start', sortable: true, value: 'symbol' },
        { text: 'Proximity', sortable: true, value: 'proximity' },
        { text: '', sortable: false, value: 'actions' }
      ]
    }
    return { ...data, ...Luxon }
  },
  computed: {
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsByNameGetter',
      'BonusLocationsRidingOnlyGetter'
    ])
  },
  created: function () {
    this.selectedBonus = new BonusLocationsPrototype()
  },
  methods: {
    groupByPage() {
      if (this.isGroup) {
        this.tableGroupBy = 'page'
        this.tableSortBy = 'entryorder'
      } else {
        this.tableGroupBy = null
        this.tableSortBy = null
      }
    },
    showBonusDetailDialog(_bonuslocation) {
      this.selectedBonus = { ..._bonuslocation }
      this.selectedBonus.__proto__ = BonusLocationsPrototype.prototype
      this.mapMarkers = [{ position: {}, icon: '' }]
      this.mapMarkers[0].position.lat = Number(_bonuslocation.latitude)
      this.mapMarkers[0].position.lng = Number(_bonuslocation.longitude)
      this.mapMarkers[0].icon =
        'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
      if (_bonuslocation.longname) {
        this.mapMarkers[0].name = _bonuslocation.longname
      } else {
        this.mapMarkers[0].name = _bonuslocation.name
      }
      if (_bonuslocation.proximity) {
        for (let proxLocation in _bonuslocation.proximity.split(',')) {
          if (
            this.BonusLocationsByNameGetter(
              _bonuslocation.proximity.split(',')[proxLocation]
            ).name !== this.selectedBonus.name
          ) {
            let proximityMarker = {
              position: {
                lat: Number(
                  this.BonusLocationsByNameGetter(
                    _bonuslocation.proximity.split(',')[proxLocation]
                  ).latitude
                ),
                lng: Number(
                  this.BonusLocationsByNameGetter(
                    _bonuslocation.proximity.split(',')[proxLocation]
                  ).longitude
                )
              },
              name:
                this.BonusLocationsByNameGetter(
                  _bonuslocation.proximity.split(',')[proxLocation]
                ).longname ||
                this.BonusLocationsByNameGetter(
                  _bonuslocation.proximity.split(',')[proxLocation]
                ).name,
              icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
            }
            this.mapMarkers.push(proximityMarker)
          }
        }
      }
      this.showLocationDetailsDialog = true
    }
  }
}
</script>
