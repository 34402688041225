<template>
  <v-dialog v-model="value" persistent max-width="80%">
    <v-card>
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title class="pl-4">Bonus location details</v-toolbar-title
        ><v-spacer />
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-container>
          <v-row dense>
            <v-col cols="1"
              ><v-text-field
                v-model="selectedBonus.name"
                label="ID"
                readonly
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="5"
              ><v-text-field
                v-model="selectedBonus.longname"
                label="Name"
                readonly
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="2"
              ><v-text-field
                v-model="selectedBonus.points"
                label="Points"
                readonly
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="2"
              ><v-text-field
                v-model="selectedBonus.page"
                label="Rallybook page"
                readonly
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="2"
              ><v-text-field
                v-model="selectedBonus.category"
                label="Category"
                readonly
                dense
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6"
              ><v-text-field
                v-model="selectedBonus.timezoneLong"
                label="Timezone"
                readonly
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="2"
              ><v-text-field
                v-model="selectedBonus.sunset"
                label="Sunset"
                readonly
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="2"
              ><v-text-field
                v-model="selectedBonus.sunrise"
                label="Sunrise"
                readonly
                dense
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"
              ><v-text-field
                v-model="selectedBonus.comment"
                label="Comment"
                readonly
                dense
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"
              ><v-text-field
                v-model="selectedBonus.description"
                label="Description"
                readonly
                dense
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"
              ><v-text-field
                v-model="selectedBonus.value"
                label="Value"
                readonly
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="2"
              ><v-text-field
                v-model="selectedBonus.symbol"
                label="Symbol"
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="mt-2">
              <v-icon
                v-if="selectedBonus.getMaterialicon()"
                small
                :color="selectedBonus.getSymbolcolor()"
                >{{ selectedBonus.getMaterialicon() }}</v-icon
              ></v-col
            >
            <v-col cols="2"
              ><v-text-field
                v-if="selectedBonus.unpaved"
                v-model="selectedBonus.unpaved"
                label="Unpaved"
                readonly
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="3"
              ><v-text-field
                v-if="selectedBonus.timerestrictions"
                v-model="selectedBonus.timerestrictions"
                label="Time restrictions"
                readonly
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="3"
              ><v-text-field
                v-if="selectedBonus.proximity"
                v-model="selectedBonus.proximity"
                label="Proximity"
                readonly
                dense
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row
            ><v-col cols="12">
              <GmapMap
                ref="mapRef"
                :options="mapOptions"
                :center="mapCenter"
                :zoom="mapZoom"
                style="width: 100%; height: 250px"
              >
                <GmapMarker
                  v-for="(m, index) in mapMarkers"
                  :key="index"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :icon="m.icon"
                /> </GmapMap
            ></v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('input', false)"
          >Dismiss</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
export default {
  name: 'DialogBonusDetail',
  props: {
    value: { required: true, type: Boolean },
    selectedBonus: { required: true, type: Object },
    mapMarkers: { required: true, type: Array }
  },
  data() {
    return {
      mapCenter: { lat: 44.902517, lng: -92.783133 },
      mapZoom: 15,
      mapOptions: {
        controlSize: 20,
        clickableIcons: false,
        streetViewControl: false,
        fullscreenControl: false,
        panControlOptions: false,
        gestureHandling: 'greedy',
        mapTypeId: 'roadmap'
      }
    }
  },
  computed: {
    google: gmapApi
  },
  watch: {
    mapMarkers() {
      this.mapCenter = {
        lat: this.mapMarkers[0].position.lat,
        lng: this.mapMarkers[0].position.lng
      }
    }
  }
}
</script>

<style></style>
