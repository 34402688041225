import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Bonus locations")])],1),_c(VCardText,[_c(VSwitch,{staticClass:"caption font-weight-thin",attrs:{"dense":"","label":"Group by page","color":"blue"},on:{"change":function($event){return _vm.groupByPage()}},model:{value:(_vm.isGroup),callback:function ($$v) {_vm.isGroup=$$v},expression:"isGroup"}}),_c(VDataTable,{attrs:{"dense":"","group-by":_vm.tableGroupBy,"sort-by":_vm.tableSortBy,"headers":_vm.tableHeaders,"items":_vm.BonusLocationsRidingOnlyGetter,"item-key":"name","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
return [_c('th',{staticClass:"subtitle-2",attrs:{"colspan":headers.length}},[_vm._v(" Page "+_vm._s(group)+" ")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [(item.category !== null)?_c(VChip,{attrs:{"color":item.getSymbolcolor() + ' lighten-3',"dark":"","pill":"","small":""}},[_vm._v(_vm._s(item.category))]):_vm._e()]}},{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"small":"","color":item.getSymbolcolor()}},[_vm._v(_vm._s(item.getMaterialicon()))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.getTshirtsize())+" ")]}},{key:"item.timezoneid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.timezoneShort)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.showBonusDetailDialog(item)}}},[_vm._v("mdi-text-box-search-outline")])]}}])})],1)],1),_c('dialogBonusDetail',{attrs:{"selectedBonus":_vm.selectedBonus,"mapMarkers":_vm.mapMarkers},model:{value:(_vm.showLocationDetailsDialog),callback:function ($$v) {_vm.showLocationDetailsDialog=$$v},expression:"showLocationDetailsDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }